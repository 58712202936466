import {
  EQuickSaleEvents,
  type IQuickSaleAllItemsSoldEvent,
  type IQuickSaleChooseAllClickedEvent,
  type IQuickSaleItemsSoldEvent,
  type IQuickSaleResetAllClickedEvent,
} from '../types/quickSale.types';

export class QuickSaleEvents {
  static allItemsSold(event: IQuickSaleAllItemsSoldEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuickSaleEvents.ALL_ITEMS_SOLD,
      eventProperties: event,
    });
  }

  static chooseAllClicked(event: IQuickSaleChooseAllClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuickSaleEvents.CHOOSE_ALL_CLICKED,
      eventProperties: event,
    });
  }

  static itemsSold(event: IQuickSaleItemsSoldEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuickSaleEvents.ITEMS_SOLD,
      eventProperties: event,
    });
  }

  static resetAllClicked(event: IQuickSaleResetAllClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuickSaleEvents.RESET_ALL_CLICKED,
      eventProperties: event,
    });
  }

  static toCasesClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EQuickSaleEvents.TO_CASES_CLICKED,
    });
  }
}

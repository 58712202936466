export enum EQuickSaleEvents {
  ALL_ITEMS_SOLD = 'Quick Sell - All Items Sold',
  CHOOSE_ALL_CLICKED = 'Quick Sell - Choose All Clicked',
  ITEMS_SOLD = 'Quick Sell - Items Sold',
  RESET_ALL_CLICKED = 'Quick Sell - Reset All Clicked',
  TO_CASES_CLICKED = 'Quick Sell - To Cases Clicked',
}

export interface IQuickSaleAllItemsSoldEvent {
  'Item Count': number;
  'Item Sum': number;
}

export interface IQuickSaleChooseAllClickedEvent {
  'Item Count': number;
}

export interface IQuickSaleItemsSoldEvent {
  'Item Count': number;
  'Item Sum': number;
}

export interface IQuickSaleResetAllClickedEvent {
  'Item Count': number;
}
